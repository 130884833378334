<template>
    <BContainer fluid>
        <h1 class="text-center">{{projects.slide2[0].projectName}}</h1>

        <BRow class="justify-content-center mt-4">
            <div class="col-12 col-xl-6">
                <BImg
                    class="image"
                    :src="projects.slide2[0].websiteImageUrl"
                    fluid
                />
            </div>

            <div class="col-12 col-xl-6">
                <h4 class="d-none d-md-block">
                    The Stack
                </h4>

                <div class="mt-md-0 mt-2 text-center text-md-left">
                    <b-badge class="vue-badge">
                        <FontAwesomeIcon
                            :icon="[ 'fab', 'vuejs' ]"
                            class="fa-2x"
                        />
                        Vue
                    </b-badge>

                    <b-badge class="ml-2 bootstrap-badge">
                        <FontAwesomeIcon
                            :icon="[ 'fab', 'bootstrap' ]"
                            class="fa-2x"
                        />
                        Bootstrap
                    </b-badge>
                </div>

                <h4 class="d-none d-md-block mt-4">
                    About
                </h4>

                <p class="mt-md-0 mt-3">
                    How to Make a Quick Buck was in short, a bit of a prank website.  My friend and I had always joked about
                    a website whose soul purpose was to display ads.  A user would enter the website due to the click-bait name,
                    choose to "enter" the website and well, the rest was ad revenue.  Passive income at its finest was the dream.
                </p>

                <p>
                    As it turns out Google Ads has some requirements that need to be met in order to place ads on a website.
                    Unfortunately these were requirements that we would never meet.
                </p>

                <p>
                    Now, the project itself wasn't an entire failure.  We got some practice setting up gitlab pipelines,
                    using gitlab pages to host a project <i>for free</i> and we learned a bit about ads along the way.  I
                    also had fun messing around with CSS styling so we elected to keep the website up.  Maybe one day
                    we can mass enough traffic to add an ad or two.
                </p>
            </div>
        </BRow>

        <ProjectNav
            :next-project="'Bane Lib'"
            :previous-project="'September Thomas'"
            :website-url="projects.slide2[0].url"
        />
    </BContainer>
</template>

<script>
    import Vue from 'vue';
    import {mapState} from 'vuex';

    import ProjectNav from '@/components/ProjectNav';

    import {library} from '@fortawesome/fontawesome-svg-core';
    import {
        faBootstrap,
        faVuejs,
    } from '@fortawesome/free-brands-svg-icons';
    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

    library.add(
        faBootstrap,
        faVuejs,
    );

    Vue.component('FontAwesomeIcon', FontAwesomeIcon);

    export default {
        name: 'QuickBuck',
        components: {
            ProjectNav,
        },
        computed: {
            ...mapState(['projects'])
        }
    }
</script>

<style
    lang="sass"
    scoped
>
    @import "/src/custom"

    .fa-4x
        color: $primary-green
        &:hover
            color: $secondary-green

    .image
        max-height: 100%
        max-width: 100%
        border-radius: 10px
</style>
